<template>
  <div>
    <van-nav-bar
      title="圈子"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="circleItem" v-for="(item, index) in list" :key="index">
      <div class="user">
        <img
          :src="
            item.Logo ||
            'https://lesson.iapeap.com/images/EmotionalValue/mrtx.png'
          "
          alt=""
        />
        <div>
          <div class="username">
            {{ decodeURIComponent(item.Prop15) || "小情绪用户**" }}
          </div>
          <div class="userxq">
            <div v-if="item.weather == 0">晴天</div>
            <div v-if="item.weather == 1">阴天</div>
            <div v-if="item.weather == 2">小雨</div>
            <div v-if="item.weather == 3">大雨</div>
            <div v-if="item.weather == 4">雪</div>
            <img
              v-if="item.weather == 0"
              src="https://lesson.iapeap.com/images/EmotionalValue/qingtianicon.png"
              alt=""
            />
            <img
              v-if="item.weather == 1"
              src="https://lesson.iapeap.com/images/EmotionalValue/duyunicon.png"
              alt=""
            />
            <img
              v-if="item.weather == 2"
              src="https://lesson.iapeap.com/images/EmotionalValue/xiaoyuicon.png"
              alt=""
            />
            <img
              v-if="item.weather == 3"
              src="https://lesson.iapeap.com/images/EmotionalValue/dayuicon.png"
              alt=""
            />
            <img
              v-if="item.weather == 4"
              src="https://lesson.iapeap.com/images/EmotionalValue/xueicon.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="circleItem-ctx">
        <div v-if="item.onlyme == 1" class="ctxjzjkj">【仅自己可见】</div>
        {{ item.comment }}
      </div>

      <div class="circleItem-imgs">
        <div
          v-for="(imgitem, imgindex) in item.img_url"
          :key="imgindex"
          @click="onimg(item)"
        >
          <img :src="imgitem" alt="" />
        </div>
      </div>
      <div class="pldz">
        <div class="dz">
          <div
            v-if="item.is_star === 'true'"
            @click="getcancelLikeEmoDiary(item)"
          >
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/dianzan.png"
              alt=""
            />
            <div>点赞{{ item.star }}</div>
          </div>
          <div v-else-if="item.is_star === 'false'" @click="dianzan(item)">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/weidianzan.png"
              alt=""
            />
            <div style="color: rgb(205, 205, 205)">点赞{{ item.star }}</div>
          </div>
        </div>
        <div class="pl" @click="showCommentInput(item)">
          <img
            src="https://lesson.iapeap.com/images/EmotionalValue/pinglun.png"
            alt=""
          />
          <div class="pl">评论</div>
        </div>
      </div>

      <div class="pinglun" v-if="item.emoCircles.length > 0">
        <div
          @click="showCommentInput2(emoCirclesitem)"
          class="pinglun-pl"
          v-for="(emoCirclesitem, emoCirclesindex) in item.emoCircles"
          :key="emoCirclesindex"
        >
          <div>
            <span>{{ decodeURIComponent(emoCirclesitem.Prop15) }}</span
            >：
          </div>
          <div>{{ decodeURIComponent(emoCirclesitem.comment) }}</div>
        </div>
        <!-- <div class="pinglun-huifu">
          <div><span>小尼</span> 回复 <span>靓仔：</span></div>
          <div>共勉！</div>
        </div> -->
      </div>
    </div>
    <div v-if="showComment" class="comment-input">
      <img
        src="https://lesson.iapeap.com/images/EmotionalValue/guanbi.png"
        alt=""
        @click="falseshow"
      />
      <textarea
        v-model="commentContent"
        placeholder="请输入评论内容"
      ></textarea>
      <button @click="submitComment()">提交</button>
    </div>

    <div v-if="showComment2" class="comment-input">
      <img
        src="https://lesson.iapeap.com/images/EmotionalValue/guanbi.png"
        alt=""
        @click="falseshow"
      />
      <textarea
        v-model="commentContent"
        placeholder="请输入回复内容"
      ></textarea>
      <button @click="submitComment2()">提交</button>
    </div>
    <van-image-preview v-model="show" :images="images" @change="onChange">
      <template v-slot:index>第{{ index }}页</template>
    </van-image-preview>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getCircleList,
  saveCircle,
  likeEmoDiary,
  cancelLikeEmoDiary,
  memberLog,
} from "../../api/login";
export default {
  data() {
    return {
      list: [],
      showComment: false, // Track the visibility of the comment input box
      showComment2: false,
      commentContent: "",
      diaryId: "",
      replayId: "",
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
      show: false,
      index: 0,
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
    };
  },
  created() {
    this.getgetcirclelist();
  },
  methods: {
    onimg(item) {
      this.show = true;
      this.images = item.img_url;
    },
    onChange(index) {
      this.index = index;
    },
    goBack() {
      this.$router.go(-1);
    },
    falseshow() {
      this.showComment2 = false;
      this.showComment = false;
    },

    // 点击回复
    showCommentInput2(item) {
      this.diaryId = item.diary_id;
      this.replayId = item.id;
      console.log(this.diaryId);
      this.showComment2 = !this.showComment2;
    },

    showCommentInput(item) {
      this.diaryId = item.id;
      console.log(this.diaryId);
      this.showComment = !this.showComment;
    },
    submitComment() {
      console.log("Comment Content:", this.commentContent);
      this.showComment = false;

      // 正则表达式匹配字符串中的表情符号
      const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
      const commentWithoutEmojis = this.commentContent.replace(
        emojiRegex,
        function (match) {
          // 对匹配到的表情符号进行编码
          return encodeURIComponent(match);
        }
      );

      let data = {
        userId: window.localStorage.getItem("userId"),
        comment: commentWithoutEmojis, // 使用处理过的评论内容
        diaryId: this.diaryId,
      };

      saveCircle(data).then((res) => {
        Toast.success("评论成功");
        this.getgetcirclelist();
        this.commentContent = ""; // 清空评论内容
      });
    },

    //回复
    submitComment2() {
      console.log("Comment Content:", this.commentContent);
      this.showComment = false;
      let data = {
        userId: window.localStorage.getItem("userId"),
        comment: encodeURIComponent(this.commentContent),
        replayId: this.replayId,
        diaryId: this.diaryId,
      };
      saveCircle(data).then((res) => {
        Toast.success("评论成功");
        this.showComment2 = false;
        this.showComment = false;
        this.getgetcirclelist();
        this.commentContent = ""; //清空评论内容
      });
    },

    getgetcirclelist() {
      Toast.loading({
        message: "正在加载...",
        forbidClick: true,
        duration: 0,
      });
      let data = { userId: window.localStorage.getItem("userId") };
      getCircleList(data).then((res) => {
        Toast.clear();
        // 对每个comment字段进行解码
        res.data.forEach((item) => {
          item.comment = decodeURIComponent(item.comment);
        });

        this.list = res.data;
        console.log(this.list);
      });
    },
    dianzan(item) {
      let data = {
        userId: window.localStorage.getItem("userId"),
        diaryId: item.id,
      };
      likeEmoDiary(data).then((res) => {
        // Toast.success("赞");
        setTimeout(() => {
          this.getgetcirclelist();
        }, 1000);
      });
    },

    getcancelLikeEmoDiary(item) {
      console.log("取消点赞");
      let data = {
        userId: window.localStorage.getItem("userId"),
        diaryId: item.id,
      };
      cancelLikeEmoDiary(data).then((res) => {
        // Toast.success("取消点赞");
        setTimeout(() => {
          this.getgetcirclelist();
        }, 1000);
      });
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "圈子",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
.van-nav-bar .van-icon {
  color: #464646;
}
.circleItem {
  padding: 20px;
  box-sizing: border-box;
  .user {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 18px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    .username {
      font-size: 15px;
      font-weight: 500;
      color: #000025;
      line-height: 21px;
    }
    .userxq {
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 500;
      color: #000025;
      line-height: 14px;
      gap: 6px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .circleItem-ctx {
    font-size: 13px;
    font-weight: 500;
    color: #000025;
    line-height: 18px;
    // display: flex;
    letter-spacing: 1px;
    .ctxjzjkj {
      color: #72adf4;
    }
  }
  .circleItem-imgs {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 18px;
    margin-bottom: 18px;
    img {
      width: 88px;
      height: 88px;
      border-radius: 10px;
    }
  }
  .pldz {
    display: flex;
    flex-direction: row-reverse;
    gap: 32px;
    .pl {
      text-align: center;
      font-size: 8px;
      font-weight: 300;
      color: #72adf4;
      line-height: 11px;
      letter-spacing: 1px;
    }
    .dz {
      text-align: center;
      font-size: 8px;
      font-weight: 300;
      color: #faaeae;
      line-height: 11px;
      letter-spacing: 1px;
    }
    img {
      width: 17px;
      height: 17px;
    }
  }

  .pinglun {
    margin-top: 10px;
    background: #f6f9fd;
    padding: 10px;
    font-size: 13px;
    span {
      color: #72adf4;
    }
    .pinglun-pl {
      display: flex;
      margin-bottom: 5px;
    }
    .pinglun-huifu {
      margin-top: 10px;
      display: flex;
    }
  }
}
.comment-input {
  width: 100%;
  padding: 20px;
  background-color: #f6f9fd;
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  img {
    float: right;
    width: 25px;
    margin-bottom: 10px;
  }
  textarea {
    width: 100%;
    height: 80px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  button {
    float: right;
    margin-top: 10px;
    padding: 8px 12px;
    font-size: 14px;
    background-color: #72adf4;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
